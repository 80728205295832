import { useEffect } from "react";
import Aos from "aos";

import ImgSection1 from "./assets/images/img-section-1.png";
import ImgSection2 from "./assets/images/img-section-2.png";

import IconSocial1 from "./assets/images/icon-social-1.png";
import IconSocial2 from "./assets/images/icon-social-2.png";
import IconSocial3 from "./assets/images/icon-social-3.png";
import IconSocial4 from "./assets/images/icon-social-4.png";
import IconCopy from "./assets/images/icon-copy.png";

import ImgContent1 from "./assets/images/img-content-1.png";
import ImgContent2 from "./assets/images/img-content-2.png";
import ImgMeme1 from "./assets/images/img-meme-1.png";
import ImgMeme2 from "./assets/images/img-meme-2.png";
import ImgMeme3 from "./assets/images/img-meme-3.png";
import ImgMeme4 from "./assets/images/img-meme-4.png";
import ImgMeme5 from "./assets/images/img-meme-5.png";
import ImgMeme6 from "./assets/images/img-meme-6.png";
import ImgMeme7 from "./assets/images/img-meme-7.png";
import ImgMeme8 from "./assets/images/img-meme-8.png";
import ImgMeme9 from "./assets/images/img-meme-9.png";

import ImgGif1 from "./assets/images/img-gif-1.gif";
import ImgGif2 from "./assets/images/img-gif-2.gif";
import ImgGif3 from "./assets/images/img-gif-3.gif";
import ImgGif4 from "./assets/images/img-gif-4.gif";
import ImgGif5 from "./assets/images/img-gif-5.gif";

import "./App.scss";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);
  return (
    <div className="App">
      <main className="page-main">
        <section className="section-intro">
          <div data-aos="fade-up" className="container wrapper">
            <h3 className="title">Buy $HARRIS ! Win with $HARRIS!</h3>
            <div className="img-intro">
              <img src={ImgSection1} alt="" />
            </div>
            <ul className="list-social">
              <li>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <img src={IconSocial1} alt="" />
                </a>
              </li>
              <li>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <img src={IconSocial2} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://x.com/KamalaHarrisOff"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconSocial3} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/KamalaHarris_Portal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconSocial4} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section className="section-about">
          <div data-aos="fade-up" className="container">
            <div className="img-section">
              <img src={ImgSection2} alt="" />
            </div>
            <div className="content">
              <div className="gif-left">
                <img src={ImgGif1} alt="" />
              </div>
              <div className="gif-right">
                <img src={ImgGif2} alt="" />
              </div>
              <div className="left">
                <div className="img-top">
                  <img src={ImgContent1} alt="" />
                </div>
                <div className="img-bottom">
                  <img src={ImgContent2} alt="" />
                </div>
              </div>
              <div className="right">
                <h3 className="title">The Simpson Right Again?!</h3>
                <p>
                  Kamala Harris is the Vice President of the United States,
                  making history as the first woman, the first Black woman, and
                  the first person of South Asian descent to hold the office.
                  Her career is marked by a steadfast commitment to justice,
                  equality, and the betterment of society. Harris was born in
                  Oakland, California, and graduated from Howard University and
                  the University of California, Hastings College of the Law. She
                  served as the District Attorney of San Francisco and as the
                  Attorney General of California before being elected to the
                  U.S. Senate in 2016. As a senator, Harris championed issues
                  such as criminal justice reform, healthcare, and environmental
                  justice.
                  <br />
                  <br /> As Vice President, Kamala Harris continues to work on
                  critical issues, including the COVID-19 pandemic response,
                  immigration reform, and efforts to address systemic
                  inequalities. Her leadership style is characterized by her
                  ability to connect with people, her advocacy for marginalized
                  communities, and her pursuit of innovative solutions to
                  complex problems.
                  <br />
                  <br /> Kamala Harris is now the official Democratic candidate
                  for the 2024 presidential election. With her extensive
                  experience and dedication to public service, she has the
                  potential to become the next President of the United States.
                </p>
              </div>
            </div>
            <p>Buy $HARRIS ! Win with $HARRIS!</p>
          </div>
        </section>
        <section className="section-tokenomics">
          <div data-aos="fade-up" className="container wrapper">
            <h3 className="title">Tokenomics</h3>
            <p className="des">
              <span>(0%)</span> Tax on Buys, Sells, & Transfers
            </p>
            <ul className="list-token">
              <li>
                <div className="img-token">
                  <img src={ImgSection1} alt="" />
                </div>
                <div className="content">
                  <p className="title">Liquidity</p>
                  <p className="value">Locked</p>
                </div>
              </li>
              <li>
                <div className="img-token">
                  <img src={ImgSection1} alt="" />
                </div>
                <div className="content">
                  <p className="title">Supply</p>
                  <p className="value">1,000,000,000</p>
                </div>
              </li>
              <li>
                <div className="img-token">
                  <img src={ImgSection1} alt="" />
                </div>
                <div className="content">
                  <p className="title">Ownership</p>
                  <p className="value">Renounced</p>
                </div>
              </li>
            </ul>
            <div className="address">
              CA: TBA
              <span
                onClick={() => {
                  navigator.clipboard.writeText("TBA");
                }}
              >
                <img src={IconCopy} alt="" />
              </span>
            </div>
          </div>
        </section>
        <section className="section-gallery">
          <div data-aos="fade-up" className="container">
            <div className="content">
              <div className="gif-left">
                <img src={ImgGif3} alt="" />
              </div>
              <div className="gif-right">
                <img src={ImgGif4} alt="" />
              </div>
              <p className="title">Meme gallery</p>
              <ul className="list-meme">
                <li>
                  <img src={ImgMeme1} alt="" />
                </li>
                <li>
                  <img src={ImgMeme2} alt="" />
                </li>
                <li>
                  <img src={ImgMeme3} alt="" />
                </li>
                <li>
                  <img src={ImgMeme4} alt="" />
                </li>
                <li>
                  <img src={ImgMeme5} alt="" />
                </li>
                <li>
                  <img src={ImgMeme6} alt="" />
                </li>
                <li>
                  <img src={ImgMeme7} alt="" />
                </li>
                <li>
                  <img src={ImgMeme8} alt="" />
                </li>
                <li>
                  <img src={ImgMeme9} alt="" />
                </li>
              </ul>
            </div>
            <div className="img-bottom">
              <img src={ImgGif5} alt="" />
            </div>
            <ul className="list-social">
              <li>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <img src={IconSocial1} alt="" />
                </a>
              </li>
              <li>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <img src={IconSocial2} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://x.com/KamalaHarrisOff"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconSocial3} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/KamalaHarris_Portal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconSocial4} alt="" />
                </a>
              </li>
            </ul>
            <p className="footer-content">
              Please be advised that HARRIS Memecoin is a cryptocurrency created
              for entertainment purposes and is not affiliated with or endorsed
              by HARRIS HARRIS. While HARRIS Memecoin may reference or
              incorporate elements related HARRIS's persona, image, or likeness,
              it does not imply any direct endorsement, partnership, or approval
              by HARRIS. Any resemblance or association between HARRIS Memecoin
              and Kamala Harris is purely coincidental and intended for
              satirical or humorous purposes.
            </p>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
